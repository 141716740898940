import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const App = () => {
  const { register, handleSubmit } = useForm();
  const [status, setStatus] = useState(""); // Status to hold success/error messages
  const [isLoading, setIsLoading] = useState(false); // Track loading state

  const onSubmit = async (data) => {
    setIsLoading(true); // Show loading indicator while sending email
    const response = await fetch("https://api.rustifydata.com/api/submit-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    setIsLoading(false); // Stop loading indicator

    if (response.ok) {
      setStatus({ message: result.message, success: true }); // Show success message
    } else {
      setStatus({ message: result.message || 'Something went wrong. Please try again later.', success: false }); // Show error message
    }
  };

  return (
    <div className="min-h-screen bg-black text-white font-sans flex flex-col justify-center items-center p-8 space-y-6">
      <header className="text-center space-y-4">
        <h1 className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-indigo-600 to-purple-700">RustifyData</h1>
        <p className="text-lg sm:text-xl max-w-2xl mx-auto opacity-70">
          A fast, lightweight, and dependency-free DataFrame library for Node.js, powered by Rust. We're building something amazing!
        </p>
      </header>

      <main className="flex flex-col items-center bg-gradient-to-b from-gray-800 to-black p-6 rounded-xl shadow-lg max-w-xl w-full">
        <p className="text-xl text-center mb-6">
          RustifyData is currently in development. We would love to hear your ideas. Drop your email below to get notified!
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col items-center space-y-4">
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            {...register("email", { required: true })}
            className="p-4 rounded-lg text-black w-72 sm:w-96 border-2 border-gray-600 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            required
          />
          <button
            type="submit"
            className="w-72 sm:w-96 bg-gradient-to-r from-indigo-500 via-purple-600 to-pink-500 text-white font-semibold py-3 rounded-lg hover:scale-105 transition duration-200"
          >
            {isLoading ? 'Submitting...' : 'Notify Me'}
          </button>
        </form>

        {status && (
          <p
            className={`mt-4 ${status.success ? 'text-green-400' : 'text-red-400'}`}
          >
            {status.message}
          </p>
        )}
      </main>

      <footer className="mt-12 text-center">
        <p className="text-sm sm:text-base opacity-80">
          Have any questions or feedback? Reach out at{" "}
          <a href="mailto:hello@RustifyData.com" className="text-blue-400 hover:text-blue-600">hello@rustifydata.com</a>
        </p>
      </footer>
    </div>
  );
};

export default App;
